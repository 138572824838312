body.isMobile{
	/** 
	 * iPad with portrait orientation.
	 */
	@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait){
	  // .foo {
	  //   height: 1024px;
	  // }
	  	
		main {
		  width: 6649px
		}
		section {
		  width: 698px;
		}
		.parallaxCtn {
		  width: 356px;
		}
		.connectorSection {
		  width: 685px;
		}
		.home {
		  width: 1396px;
		}
		.tilesCtnInner {
		  width: 2156px;
		}
		.tile {
		  width: 308px;
		  height: 308px;
		}
		.scrollTxt {
		  height: 924px;
		  margin-left: 638px;
		}
		.scrollTxt.unfixed {
		  margin-left: 1336px;
		}
		.centeredLogo {
		  margin: 346px 0 0 216px;
		}
		.centeredLogo.unfixed {
		  margin: 346px 0 0 914px;
		}
		article {
		  left: 1361px;
		}
		article.fixed {
		  height: 924px;
		}
		article.fixed.fixedRight {
		  right: 682px;
		  left auto
		}
		.articleCtn {
		  height: 624px;
		}
	}

	/** 
	 * iPad with landscape orientation.
	 */
	@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape){
	  // .foo {
	  //   height: 768px;
	  // }
	  	main {
		  width: 8410px;
		}
		section {
		  width: 954px;
		}
		.parallaxCtn {
		  width: 707px;
		}
		.connectorSection {
		  width: 495px;
		}
		.home {
		  width: 1908px;
		}
		.tilesCtnInner {
		  width: 1559px;
		}
		.tile {
		  width: 223px;
		  height: 223px;
		}
		.scrollTxt {
		  height: 668px;
		  margin-left: 894px;
		}
		.scrollTxt.unfixed {
		  margin-left: 1848px;
		}
		.claim, .claim01, .claim02{
			top 100px
		}
		.centeredLogo {
		  margin: 218px 0 0 344px;
		}
		.centeredLogo.unfixed {
		  margin: 218px 0 0 1298px;
		}
		article {
		  left: 1943px;
		}
		article.fixed {
		  left 70px
		  height: 668px;
		}
		article.fixed.fixedRight {
		  right: 1066px;
		  left auto
		}
		.articleCtn {
		  height: 368px;
		}
	}

	/**
	 * iPhone 5
	 * You can also target devices with aspect ratio.
	 */
	@media screen and (device-aspect-ratio: 40/71) {
	  // .foo {
	  //   height: 500px;
	  // }
	}
}