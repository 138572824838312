.navButtonCtn{
	display none
}

.frameBorder{
	position-absolute(0, auto, 0, auto)
	position fixed
	background #fff
	width 35px
	z-index 300
}

.frameBorder-right{
	@extend .frameBorder
	right 0
}

.frameBorder-left{
	@extend .frameBorder
	left 0
}

$fullSectionWidth = calc(100vw - 70px)
$parallaxSectionWidth = calc((100vw - 70px) - ((100vh - 100px) / 1.35) / 2)
$connectorSectionWidth = calc((100vh - 100px) / 1.35)

main{
	position-absolute(35px, 35px, 65px, 35px)
	//width "calc(%s * 7 + %s * 3 + %s * 3 + 35px)" % ($fullSectionWidth $parallaxSectionWidth $connectorSectionWidth)
	// above not working because Safari does not support nested calc statements
	width calc((100vw - 70px) * 7 + ((100vw - 70px) - ((100vh - 100px) / 1.35) / 2) * 3 + ((100vh - 100px) / 1.35) * 3 + 35px) !important
	// 1. param = $fullSectionWidth; 2. param = $parallaxSectionWidth; 3. param = $connectorSectionWidth
	z-index 10
	margin 0
}

section{
	overflow hidden
	position relative
	float left
	width $fullSectionWidth
	height 100%
	article{
		display none
	}
}

.parallaxCtn{
	width $parallaxSectionWidth
}

.connectorSection{
	width $connectorSectionWidth
}

.home, .contact{
	background #000
}

.home{
	width calc((100vw - 70px)*2)
}

#map{
	width 100%
	height 100%
}

footer{
	position-absolute(auto, 0, 0, 35px)
	position fixed
	height 65px
	background #fff
	z-index 200
	
	> .noticeLink{
		font-size 15px
		color #000
		text-transform uppercase
		display inline-block
		text-align center
		line-height 65px
		position-absolute(0, 35px, 0, auto)
		&:hover{
			color #aaa
		}
	}
	
	nav{
		ul{
			list-style-type none
			padding 0
			// margin 0 0 0 75px
			margin 0
			li{
				display inline-block
				margin-right 15px
				a{
					font-size 18px
					color #000
					text-transform uppercase
					line-height 65px
				}
				&.active {
					a{
						color #aaa
					}
				}
				&.noticeLink{
					display none
				}
			}
		}
	}
}