body.siteNotice{
	position relative
	color #fff
	background #fff
	main{
		text-align center
		overflow auto
		background #000
		padding 35px
		width calc(100% - 70px) !important
		margin 0 auto
		height auto
		position-absolute(35px, 35px, 65px, 35px)
		.view{
			width 80%
			position absolute
			left 0
			right 0
			margin auto
			height auto
			a{
				color #ddd
				text-decoration underline
			}
		}
	}
}