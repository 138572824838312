.my-marker{
	height 55px
	width 31px
	cursor pointer
}

.markerInfobubble{
	// background #fff
	// width 220px
	// margin auto
	// position relative
	// box-shadow(0, 1px, 3px rgba(0,0,0,0.2))
	figure{
		margin 0
		// margin 0 0 55px
		padding 8px 0 10px 0
		img{
			display block
			margin auto auto 10px
		}
		figcaption{
			font-family 'Playfair Display'
			font-size 16px
			text-align center
			// &:after{
			// 	content ''
			// 	display block
			// 	position-absolute(auto, auto, -15px, 50%)
			// 	transform translate(-50%, 0)
			// 	border-right 15px solid transparent
			// 	border-left 15px solid transparent
			// 	border-top 15px solid #fff
			// 	width 0
			// 	height 0
			// }
		}
	}
}