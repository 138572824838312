.contact{
	color #fff
	text-align center
	.logo{
		margin-top 50px
	}
	form{
		color #000
		max-width 400px
		width 100%
		margin 0 auto
		display block
		input, textarea{
			text-align left
			display block
			width 100%
			margin-bottom 15px
			padding 10px 15px
			background #fff
			border none
		}
		input[type=submit]{
			display inline-block
			text-align center
			margin 0
			width auto
			padding 10px 25px
		}
		
		input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill{
			background-color #fff !important
		}
	}
	
	.formSuccess, .formFailure{
		display none
	}
	
	.formSuccess{
		font-size 24px
	}
	
	.formFailure{
		margin-bottom 20px
		button{
			display inline-block
			text-align center
			margin 20px 0 0
			width auto
			padding 10px 25px
			border none
			background #fff
			color #000
		}
	}
	
	&.splitted{
		.split{
			// position-absolute(50%, auto, auto, 0)
			position absolute
			top 50%
			left 5%
			width 45%
			// width 50%
			margin auto
			transform translate(0, -50%)
			.logo{
				margin auto
			}
		}
		
		.split + .split{
			right 5%
			top 0
			bottom 0
			left auto
			margin auto
			transform none
			form, .formSuccess, .formFailure{
				position relative
				// margin-top 10%
				// transform translateY(-50%)
				position-absolute(0,0,0,0)
				margin auto
			}
			form{
				height 310px
			}
			.formSuccess{
				height 70px
			}
			.formFailure{
				height 120px
			}
		}
	}
}

.contact-info{
	width 300px
	margin 0 auto 35px auto
}