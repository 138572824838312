*{
	&
	&:before
	&:after{
		box-sizing border-box
	}
}

html, body{
	height 100%
}

body{
	background #fff
	font-size 19px
	font-family 'Playfair Display'
	color #000
	overflow-y hidden
	-webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale
}

h1{
	font-size 30px
}

h2{
	font-size 26px
}

h3{
	font-size 22px
}

a{
	color #000
	text-decoration none
	transition color .3s
	&:hover{
		color #aaa
		text-decoration none
	}
	&.underlined{
		span{
			// text-decoration underline
			position relative
			color #fff
			transition .2s color
			&:after{
				position-absolute(auto, 0, 0px, 0)
				display inline-block
				content ''
				height 1px
				border-top 1px solid #fff
				transition .2s border-top-color
			}
		}
		&:hover{
			span{
				color #ddd
				&:after{
					border-top-color #ddd
				}
			}
		}
	}
}

.logo{
	width 265px
	height 83px
	background url('../img/logo.png') no-repeat
	text-indent -9999px
	display block
	margin auto
}

// ::-webkit-scrollbar {
//     width: 0px;
// }
// ::-webkit-scrollbar-track {
//     background-color: #fff;
//     border-left: none;
// }
// ::-webkit-scrollbar-thumb {
//     background-color: #fff;
// }
// ::-webkit-scrollbar-thumb:hover {
//     background-color: #fff;
// }