.backgrounds{
	background-repeat no-repeat
	// position-absolute(0, auto, 0, 0)
	background-size cover
	width 100%
	height 100%
	
	&.parallaxItem{
		width 150%
		position relative
		left -50%
	}
}

.bg_pic01{
	@extend .backgrounds	
	background-image url('../img/bg-1.jpg')
}

.bg_pic02{
	@extend .backgrounds	
	background-image url('../img/bg-2.jpg')
}

.bg_pic03{
	@extend .backgrounds	
	background-image url('../img/bg-3.jpg')
}

.bg_pic04{
	@extend .backgrounds	
	background-image url('../img/bg-4.jpg')
}

.connectors{
	background-repeat no-repeat
	background-size cover
	height 100%
	width 100%
}

.connector01{
	@extend .connectors	
	background-image url('../img/parallax-1.jpg')
}

.connector02{
	@extend .connectors	
	background-image url('../img/parallax-2.jpg')
}

.connector03{
	@extend .connectors	
	background-image url('../img/parallax-3.jpg')
}

.connector04{
	@extend .connectors	
	background-image url('../img/parallax-1.jpg')
}

.tilesCtn{
	background-color #000
}

.tilesCtnInner{
	height 100%
	width calc(((100vh - 100px) / 3) * 7)
	position relative
	// left -50%
	// transform translate(50%)
}

.tile {
	background-repeat no-repeat
	background-size cover
	float left
	width calc((100vh - 100px) / 3)
	height calc((100vh - 100px) / 3)
}

.tile01{
	background-image url('../img/tile-01.jpg')
}

.tile02{
	background-image url('../img/tile-02.jpg')
}

.tile03{
	background-image url('../img/tile-03.jpg')
}

.tile04{
	background-image url('../img/tile-04.jpg')
}

.tile05{
	background-image url('../img/tile-05.jpg')
}

.tile06{
	background-image url('../img/tile-06.jpg')
}

.tile07{
	background-image url('../img/tile-07.jpg')
}

.tile08{
	background-image url('../img/tile-08.jpg')
}

.tile09{
	background-image url('../img/tile-09.jpg')
}

.tile10{
	background-image url('../img/tile-10.jpg')
}

.tile11{
	background-image url('../img/tile-11.jpg')
}

.tile12{
	background-image url('../img/tile-12.jpg')
}

.tile13{
	background-image url('../img/tile-13.jpg')
}

.tile14{
	background-image url('../img/tile-14.jpg')
}

.tile15{
	background-image url('../img/tile-15.jpg')
}

.tile16{
	background-image url('../img/tile-16.jpg')
}

.tile17{
	background-image url('../img/tile-17.jpg')
}

.tile18{
	background-image url('../img/tile-18.jpg')
}

.tile19{
	background-image url('../img/tile-19.jpg')
}

.tile20{
	background-image url('../img/tile-20.jpg')
}

.tile21{
	background-image url('../img/tile-21.jpg')
}

// .tile22{
// 	background-image url('../img/tile-22.jpg')
// }

// .tile23{
// 	background-image url('../img/tile-23.jpg')
// }

// .tile24{
// 	background-image url('../img/tile-24.jpg')
// }

// .tile25{
// 	background-image url('../img/tile-25.jpg')
// }

.tile.tileLogo{
	background-color #000
	background-image none !important
	padding 30px
}

.individualistLogo{
	background-image url('../img/individualist-2x.png')
	background-size contain
	background-repeat no-repeat
	background-position 50%
	width 100%
	height 100%
}