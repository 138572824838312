body.isMobile{
	article{
		.articleCtn{
			font-size 17px
			height calc(100% - 85px)
		}
	}
	.latLongCtn{
		display none
	}
}
