// .homeContent{
// 	width 100%
// 	height 100%
// 	position-absolute(0,0,0,0)
// }

.scrollTxt{
 	width 60px
 	height calc(100vh - 100px)
 	margin-left calc(100vw - 70px - 60px)
 	position fixed
 	background #000
 	z-index 1
 	
 	&.unfixed{
		position-absolute(0,0,0,0)
		margin-left calc(200vw - 60px - 140px)
 	}
	span{
		color #fff
		
		position-absolute(0, 0, 0, -75px)
		width 210px
		height 30px
		transform rotate(-90deg)
	}
}

.centeredLogo{
	@extend .logo
	
	margin calc(50vh - 83px) 0 0 calc(50vw - 35px - 133px)
	position fixed
	&.unfixed{
		position-absolute(0,0,0,0)
		margin calc(50vh - 83px) 0 0 calc(150vw - 105px - 133px)
	}
	.claim{
		background url('../img/claim.png') no-repeat
		width 247px
		height 20px
		margin 0 auto
	}
}

.claim{
		background-repeat no-repeat
		background-position 50%
		position-absolute(135px, auto, 0, auto)
		width 400px
		height 20px
		margin auto
		transform translate(-50%)
}

.claim01{
	@extend .claim
	background-image url('../img/claim.png')
	left 25%
}

.claim02{
	@extend .claim
	background-image url('../img/claim-2.png')
	left 75%
}