article{
	z-index 100
	color #fff
	text-align center
	background #000
	width 400px
	height 100%
	padding 35px 45px
	overflow hidden
	position-absolute(0, auto, 0, calc((100vw - 70px) * 2 + 35px))
	margin 0
	&.fixed{
		position fixed
		left 70px
		top 35px
		height calc(100vh - 100px)
		&.fixedRight{
			position absolute
			left auto
			right calc(250vw - 140px - 400px)
			top 0 
			height 100%
		}
	}
}

.main header
	z-index 102

.articleCtn{
	height: 100%;
	margin-top: -20%;
	overflow hidden
	position relative
	z-index 101
}

// .article__content{
// 	position-absolute(auto, 0, -120%, 0)
// 	height 100%
// 	min-height 320px
// }
.article__content{
	position-absolute(0, 0, 0, 0)
	height 100%
	min-height 320px
	z-index 1
	opacity 0
	transition .3s opacity
	.article__content__div{
		position relative
		top 50%
		margin-top -50%
	}
	
	&.active{
		z-index 2
		opacity 1
	}
}

.latLongCtn{
	font-size 38px
	font-family 'Open Sans'
	width 100%
}