.comic
	background #000 url("../img/bg-5.jpg") no-repeat
	background-position 50% 40%
	background-size 70%
	.quote
		color #FFF
		text-align center
		position absolute
		margin 10% auto
		left 0
		right 0
		top 50%